import { scrollToTop } from '../../util/scrollToTop';
import { NavLinkButton } from '../generic/NavLinkButton';
import { linkedinLink } from '../../appconfig';
import { LinkedinIcon } from '../../icons/LinkedinIcon';
import { CosmithIcon } from '../../icons/Cosmith';
export const AboutUasCosmithCard = () => {
  return (
    <li className='flex flex-col items-center justify-between p-6 w-[276px] h-[332px] rounded-[10px] bg-cosmith-lavanda-150'>
      <div className='w-[96px] h-[96px]  flex items-center justify-center rounded-full mb-[20px] border-[0.75px] border-black border-solid border-opacity-10 '>
        <CosmithIcon className='w-[80px] h-[80px]' />
      </div>

      <div className='flex flex-col h-[132px] justify-around'>
        <NavLinkButton
          to='/category'
          type='primary'
          size='normal'
          className='min-w-[9.75rem] max-w-[9.75rem] block'
          onClick={scrollToTop}
        >
          Buy now
        </NavLinkButton>
        <NavLinkButton
          to='/about-us#aboutUsContacts'
          type='link'
          size='normal'
          className='min-w-[9.75rem] max-w-[9.75rem] block'
          onClick={() => {
            const section = document.getElementById('aboutUsContacts');
            if (section) {
              section.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        >
          Talk to an expert
        </NavLinkButton>
      </div>
      <a
        href={linkedinLink}
        target='_blank'
        rel='noopener noreferrer'
        className='block hover:scale-150 transition-transform duration-300 ease-out  p-1 focus-outline-custom'
      >
        <LinkedinIcon />
      </a>
    </li>
  );
};
