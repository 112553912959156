// TODO: move to the Firebase
import MarharytaImage from '../../assets/team/MarharytaOhorodniichuk.jpeg';
import EduardImage from '../../assets/team/EduardPanasenko.png';
import OlenaImage from '../../assets/team/PosternakOlena.jpg';
import VladyslavImage from '../..//assets/team/VladyslavKazistov.jpeg';
import MykhailoImage from '../../assets/team/MykhailoLukianenko.jpg';
import YavheniiImage from '../../assets/team/StukaloYavhenii.jpg';
import VitaliiImage from '../../assets/team/VitaliiVeshtobei.jpeg';

export const teamData = [
  {
    id: 2,
    name: 'Marharyta Ohorodniichuk',
    image: MarharytaImage,
    position: 'Lead of QA',
    about:
      'QA Engineer with 4 years of experience, specializing in ensuring quality and overseeing delivery processes within the team. Experienced in working across various domains and holding an ISTQB certification.',
    aboutShort:
      'QA Engineer with 4 years in QA and delivery oversight, ISTQB-certified.',
    linkedin: 'https://www.linkedin.com/in/marharyta-ohorodniichuk-b89591217',
  },
  {
    id: 3,
    name: 'Eduard Panasenko',
    image: EduardImage,
    position: 'FlutterFlow Developer',
    about:
      'A software developer with 4 years of experience. Specializing in Dart, FlutterFlow, and Java.',
    aboutShort:
      'Software Developer with 4 years of experience in Dart, FlutterFlow, and Java.',
    linkedin: 'https://www.linkedin.com/in/eduard-panasenko-096875223/',
  },

  {
    id: 4,
    name: 'Vitalii Veshtobei',
    image: VitaliiImage,
    position: 'FrontEnd Developer',
    about: '',
    aboutShort:
      'Software Developer with expertise in Adalo, React and TypeScript.',
    linkedin: 'https://www.linkedin.com/in/vitaliiveshtobei/',
  },
  {
    id: 5,
    name: 'Olena Posternak',
    image: OlenaImage,
    position: 'FlutterFlow Developer',
    about:
      'Software Developer proficient in FlutterFlow, React, and TypeScript. Passionate about creating seamless user experiences and efficient solutions.',
    aboutShort:
      'Software Developer skilled in FlutterFlow, React and TypeScript.',
    linkedin: 'https://www.linkedin.com/in/posternak-olena/',
  },
  {
    id: 6,
    name: 'Vladyslav Kazistov',
    image: VladyslavImage,
    position: 'FlutterFlow Developer',
    about:
      'I am an IT professional with a bachelor’s degree in Computer Science and currently pursuing a master’s in Software Engineering at Kyiv-Mohyla Academy. My expertise includes both pro-code programming languages and low-code solutions like FlutterFlow, Adalo, and more.',
    aboutShort:
      'Software Developer skilled in pro-code and low-code tools like FlutterFlow and Adalo.',
    linkedin: 'https://www.linkedin.com/in/vladyslav-kazistov-80a408280/',
  },

  {
    id: 7,
    name: 'Mykhailo Lukianenko',
    image: MykhailoImage,
    position: 'FlutterFlow Developer',
    about:
      'Junior FrontEnd Developer with experience in building web applications and websites using React, Tailwind CSS, FlutterFlow, and WordPress.',
    aboutShort:
      'Junior Frontend Developer with experience in React, FlutterFlow, and WordPress.',
    linkedin: 'https://www.linkedin.com/in/mykhaylo-lukianenko-393122244/',
  },
  {
    id: 8,
    name: 'Yevhenii Stukalo',
    image: YavheniiImage,
    position: 'FrontEnd Developer',
    about:
      'Junior FrontEnd developer. I can create single-page websites using React, Angular. I also have experience creating websites using vanilla JS. I have a technical mindset, like to find solutions to various problems, and work in a team. I am looking for a project where I can improve my skills in a team of experienced developers.',
    aboutShort:
      'Junior Frontend Developer with experience in React, Angular, and Adalo.',
    linkedin: 'https://www.linkedin.com/in/yevgenii-stukalo-3a9193208/',
  },
];
