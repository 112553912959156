interface MainLogoProp {
  type: 'black' | 'white';
}

export const MainLogo = ({ type }: MainLogoProp) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='180'
      height='30'
      viewBox='0 0 180 30'
      className={type === 'black' ? 'fill-cosmith-dark-600' : 'fill-white'}
    >
      <path d='M58.4336 15.5207C58.4336 14.7225 58.5575 13.9795 58.8052 13.2915C59.053 12.5897 59.4039 11.9842 59.8582 11.4751C60.3261 10.9659 60.8904 10.5669 61.5511 10.2779C62.2118 9.98896 62.955 9.84447 63.7808 9.84447C64.7994 9.84447 65.7628 10.0302 66.6713 10.4018L66.1345 12.4452C65.8454 12.3214 65.5151 12.2181 65.1435 12.1356C64.7856 12.053 64.4002 12.0117 63.9873 12.0117C63.0101 12.0117 62.2668 12.3214 61.7576 12.9406C61.2483 13.546 60.9937 14.4061 60.9937 15.5207C60.9937 16.594 61.2345 17.4471 61.7163 18.0801C62.198 18.6993 63.0101 19.0089 64.1525 19.0089C64.5791 19.0089 64.9989 18.9676 65.4119 18.8851C65.8248 18.8025 66.1826 18.6993 66.4854 18.5755L66.8364 20.6395C66.5611 20.7771 66.1413 20.901 65.577 21.0111C65.0265 21.1212 64.4553 21.1762 63.8634 21.1762C62.9413 21.1762 62.1361 21.0386 61.4479 20.7634C60.7734 20.4744 60.2091 20.0822 59.7549 19.5869C59.3145 19.0777 58.9841 18.4791 58.7639 17.7911C58.5437 17.0893 58.4336 16.3325 58.4336 15.5207Z' />
      <path d='M78.3142 15.5C78.3142 16.3532 78.1903 17.1306 77.9425 17.8324C77.6948 18.5342 77.3438 19.1328 76.8896 19.6281C76.4354 20.1235 75.8849 20.5088 75.238 20.784C74.6048 21.0592 73.9029 21.1968 73.1321 21.1968C72.3613 21.1968 71.6594 21.0592 71.0262 20.784C70.3931 20.5088 69.8494 20.1235 69.3952 19.6281C68.941 19.1328 68.5831 18.5342 68.3216 17.8324C68.0739 17.1306 67.95 16.3532 67.95 15.5C67.95 14.6469 68.0739 13.8763 68.3216 13.1883C68.5831 12.4865 68.941 11.8879 69.3952 11.3925C69.8632 10.8971 70.4137 10.5187 71.0469 10.2573C71.68 9.98208 72.3751 9.84447 73.1321 9.84447C73.8891 9.84447 74.5842 9.98208 75.2173 10.2573C75.8642 10.5187 76.4148 10.8971 76.869 11.3925C77.3232 11.8879 77.6742 12.4865 77.9219 13.1883C78.1834 13.8763 78.3142 14.6469 78.3142 15.5ZM75.7541 15.5C75.7541 14.4267 75.5201 13.5804 75.0521 12.9612C74.5979 12.3282 73.9579 12.0117 73.1321 12.0117C72.3063 12.0117 71.6594 12.3282 71.1914 12.9612C70.7372 13.5804 70.5101 14.4267 70.5101 15.5C70.5101 16.5871 70.7372 17.4471 71.1914 18.0801C71.6594 18.7131 72.3063 19.0296 73.1321 19.0296C73.9579 19.0296 74.5979 18.7131 75.0521 18.0801C75.5201 17.4471 75.7541 16.5871 75.7541 15.5Z' />
      <path d='M83.5924 19.1121C84.2531 19.1121 84.7348 19.0364 85.0376 18.8851C85.3404 18.72 85.4918 18.4447 85.4918 18.0595C85.4918 17.7017 85.3266 17.4058 84.9963 17.1719C84.6797 16.938 84.1498 16.6834 83.4066 16.4082C82.9524 16.2431 82.5326 16.0711 82.1472 15.8922C81.7756 15.6995 81.4521 15.4794 81.1768 15.2317C80.9016 14.984 80.6813 14.6881 80.5162 14.3441C80.3648 13.9864 80.2891 13.5529 80.2891 13.0438C80.2891 12.053 80.6538 11.2756 81.3833 10.7114C82.1128 10.1334 83.1038 9.84447 84.3563 9.84447C84.9894 9.84447 85.595 9.9064 86.1731 10.0302C86.7512 10.1403 87.1848 10.2504 87.4738 10.3605L87.0196 12.3833C86.7443 12.2594 86.3933 12.1493 85.9666 12.053C85.54 11.9429 85.0445 11.8879 84.4802 11.8879C83.9709 11.8879 83.558 11.9773 83.2414 12.1562C82.9248 12.3214 82.7666 12.5828 82.7666 12.9406C82.7666 13.1195 82.7941 13.2777 82.8491 13.4153C82.918 13.5529 83.0281 13.6836 83.1795 13.8075C83.3309 13.9176 83.5304 14.0345 83.7782 14.1584C84.0259 14.2685 84.3288 14.3854 84.6866 14.5093C85.2785 14.7294 85.7808 14.9496 86.1938 15.1698C86.6067 15.3762 86.9439 15.617 87.2054 15.8922C87.4807 16.1536 87.6802 16.4564 87.8041 16.8004C87.928 17.1444 87.9899 17.5572 87.9899 18.0388C87.9899 19.0708 87.6045 19.8552 86.8338 20.3918C86.0768 20.9147 84.9894 21.1762 83.5717 21.1762C82.622 21.1762 81.8581 21.0936 81.2801 20.9285C80.702 20.7771 80.2959 20.6533 80.062 20.557L80.4955 18.4723C80.8671 18.6236 81.3076 18.7681 81.8169 18.9057C82.3399 19.0433 82.9317 19.1121 83.5924 19.1121Z' />
      <path d='M96.476 15.2317C96.476 14.1033 96.3315 13.2915 96.0424 12.7961C95.7671 12.287 95.2441 12.0324 94.4733 12.0324C94.1981 12.0324 93.8953 12.053 93.5649 12.0943C93.2346 12.1356 92.9869 12.17 92.8217 12.1975V20.9079H90.3235V10.4637C90.8053 10.3261 91.4315 10.1954 92.2023 10.0715C92.9869 9.94768 93.8127 9.88575 94.6798 9.88575C95.4231 9.88575 96.0287 9.98208 96.4966 10.1747C96.9784 10.3674 97.3775 10.6219 97.6941 10.9384C97.8455 10.8283 98.0382 10.7114 98.2722 10.5875C98.5062 10.4637 98.7677 10.3536 99.0567 10.2573C99.3457 10.1472 99.6486 10.0578 99.9651 9.98896C100.295 9.92016 100.626 9.88575 100.956 9.88575C101.796 9.88575 102.484 10.0096 103.021 10.2573C103.571 10.4912 103.998 10.8283 104.301 11.2687C104.617 11.6953 104.831 12.2181 104.941 12.8374C105.065 13.4428 105.127 14.1102 105.127 14.8395V20.9079H102.628V15.2317C102.628 14.1033 102.491 13.2915 102.216 12.7961C101.94 12.287 101.41 12.0324 100.626 12.0324C100.227 12.0324 99.8481 12.1012 99.4903 12.2388C99.1324 12.3626 98.864 12.4865 98.6851 12.6103C98.7952 12.9543 98.8709 13.319 98.9122 13.7043C98.9535 14.0896 98.9741 14.5024 98.9741 14.9427V20.9079H96.476V15.2317Z' />
      <path d='M110.706 20.9079H108.208V10.1128H110.706V20.9079ZM110.974 6.95478C110.974 7.42264 110.823 7.79417 110.52 8.06938C110.217 8.34459 109.859 8.48219 109.446 8.48219C109.02 8.48219 108.655 8.34459 108.352 8.06938C108.049 7.79417 107.898 7.42264 107.898 6.95478C107.898 6.47317 108.049 6.09475 108.352 5.81955C108.655 5.54434 109.02 5.40673 109.446 5.40673C109.859 5.40673 110.217 5.54434 110.52 5.81955C110.823 6.09475 110.974 6.47317 110.974 6.95478Z' />
      <path d='M113.769 7.26439L116.267 6.85158V10.1128H120.107V12.1975H116.267V16.594C116.267 17.4609 116.405 18.0801 116.68 18.4516C116.955 18.8232 117.423 19.0089 118.084 19.0089C118.538 19.0089 118.937 18.9608 119.281 18.8644C119.639 18.7681 119.921 18.6787 120.128 18.5961L120.541 20.5776C120.252 20.7015 119.873 20.8253 119.405 20.9491C118.937 21.0868 118.387 21.1556 117.754 21.1556C116.983 21.1556 116.336 21.0523 115.813 20.8459C115.304 20.6395 114.898 20.3437 114.595 19.9584C114.292 19.5593 114.079 19.0846 113.955 18.5342C113.831 17.97 113.769 17.3302 113.769 16.6146V7.26439Z' />
      <path d='M122.522 20.9079V5.30353L125.02 4.89072V10.2366C125.295 10.1403 125.612 10.0578 125.97 9.98896C126.342 9.92016 126.706 9.88575 127.064 9.88575C127.931 9.88575 128.647 10.0096 129.211 10.2573C129.789 10.4912 130.25 10.8283 130.595 11.2687C130.939 11.6953 131.18 12.2113 131.317 12.8167C131.469 13.4222 131.544 14.0964 131.544 14.8395V20.9079H129.046V15.2317C129.046 14.6537 129.005 14.1653 128.922 13.7662C128.853 13.3534 128.73 13.0231 128.551 12.7754C128.385 12.514 128.158 12.3282 127.869 12.2181C127.58 12.0943 127.222 12.0324 126.796 12.0324C126.465 12.0324 126.128 12.0668 125.784 12.1356C125.44 12.2044 125.185 12.2663 125.02 12.3214V20.9079H122.522Z' />
      <path d='M137.346 19.5456C137.346 20.0272 137.181 20.4194 136.851 20.7221C136.534 21.0248 136.149 21.1762 135.695 21.1762C135.227 21.1762 134.834 21.0248 134.518 20.7221C134.201 20.4194 134.043 20.0272 134.043 19.5456C134.043 19.064 134.201 18.6718 134.518 18.3691C134.834 18.0526 135.227 17.8943 135.695 17.8943C136.149 17.8943 136.534 18.0526 136.851 18.3691C137.181 18.6718 137.346 19.064 137.346 19.5456Z' />
      <path d='M142.541 20.9079H140.043V10.1128H142.541V20.9079ZM142.81 6.95478C142.81 7.42264 142.658 7.79417 142.356 8.06938C142.053 8.34459 141.695 8.48219 141.282 8.48219C140.855 8.48219 140.491 8.34459 140.188 8.06938C139.885 7.79417 139.734 7.42264 139.734 6.95478C139.734 6.47317 139.885 6.09475 140.188 5.81955C140.491 5.54434 140.855 5.40673 141.282 5.40673C141.695 5.40673 142.053 5.54434 142.356 5.81955C142.658 6.09475 142.81 6.47317 142.81 6.95478Z' />
      <path d='M155.494 15.5C155.494 16.3532 155.37 17.1306 155.122 17.8324C154.874 18.5342 154.523 19.1328 154.069 19.6281C153.615 20.1235 153.065 20.5088 152.418 20.784C151.784 21.0592 151.083 21.1968 150.312 21.1968C149.541 21.1968 148.839 21.0592 148.206 20.784C147.573 20.5088 147.029 20.1235 146.575 19.6281C146.121 19.1328 145.763 18.5342 145.501 17.8324C145.254 17.1306 145.13 16.3532 145.13 15.5C145.13 14.6469 145.254 13.8763 145.501 13.1883C145.763 12.4865 146.121 11.8879 146.575 11.3925C147.043 10.8971 147.593 10.5187 148.227 10.2573C148.86 9.98208 149.555 9.84447 150.312 9.84447C151.069 9.84447 151.764 9.98208 152.397 10.2573C153.044 10.5187 153.594 10.8971 154.049 11.3925C154.503 11.8879 154.854 12.4865 155.102 13.1883C155.363 13.8763 155.494 14.6469 155.494 15.5ZM152.934 15.5C152.934 14.4267 152.7 13.5804 152.232 12.9612C151.778 12.3282 151.138 12.0117 150.312 12.0117C149.486 12.0117 148.839 12.3282 148.371 12.9612C147.917 13.5804 147.69 14.4267 147.69 15.5C147.69 16.5871 147.917 17.4471 148.371 18.0801C148.839 18.7131 149.486 19.0296 150.312 19.0296C151.138 19.0296 151.778 18.7131 152.232 18.0801C152.7 17.4471 152.934 16.5871 152.934 15.5Z' />
      <path
        d='M15.7495 0.00190784C16.5799 0.585633 17.5071 1.25274 18.0274 2.22339C18.4377 2.98724 17.5638 3.34748 17.1136 3.78111C13.5515 7.2034 9.99951 10.6357 6.38745 14.008C5.58366 14.7585 5.58366 15.1587 6.38745 15.9092C10.0395 19.3149 13.6216 22.8005 17.2536 26.2295C17.9674 26.9033 18.6478 27.5537 17.5071 28.3209C16.64 28.9046 16.2131 30.8793 14.5254 29.2382C9.9128 24.7485 5.23346 20.3222 0.554128 15.9026C-0.1963 15.1921 -0.176299 14.7084 0.564123 14.0113C5.28014 9.54831 9.96949 5.0553 14.6655 0.572288C14.9423 0.302106 15.1858 -0.0281123 15.7495 0.00190784Z'
        fill='#AFE7BB'
      />
      <path
        d='M34.3374 29.9998C33.2768 29.4661 32.4963 28.6622 31.8793 27.7683C31.3423 26.9911 32.2229 26.6242 32.6631 26.1972C36.2251 22.7749 39.7738 19.3393 43.3925 15.977C44.2364 15.1931 44.3131 14.7829 43.4192 13.9556C39.7538 10.5634 36.1818 7.07103 32.5464 3.64873C31.7926 2.93826 31.299 2.27447 32.3863 1.53731C33.2634 0.94358 33.707 -0.934344 35.378 0.706757C39.9306 5.17642 44.5766 9.55269 49.2159 13.9323C49.9963 14.6695 50.063 15.1798 49.2459 15.9503C44.5632 20.3666 39.9206 24.8229 35.2612 29.2626C34.9878 29.5228 34.6676 29.7362 34.3374 29.9998Z'
        fill='#AFE7BB'
      />
      <path
        d='M16.658 17.8346C15.1505 17.8813 13.7763 16.5937 13.7096 15.0694C13.6396 13.485 14.987 12.0807 16.5946 12.0707C18.1055 12.0607 19.4362 13.3883 19.4562 14.9193C19.4763 16.4503 18.1922 17.7912 16.658 17.8346Z'
        fill='#AFE7BB'
      />
      <path
        d='M27.8025 14.9142C27.8258 16.4419 26.5417 17.7794 25.0009 17.8228C23.4633 17.8695 22.1159 16.612 22.0459 15.0709C21.9725 13.4699 23.3099 12.0789 24.9208 12.0789C26.4584 12.0823 27.7758 13.3798 27.8025 14.9142Z'
        fill='#AFE7BB'
      />
      <path
        d='M33.2317 17.8365C31.7175 17.8332 30.3967 16.5023 30.3867 14.9713C30.3801 13.4336 31.6808 12.0927 33.1983 12.076C34.8093 12.0593 36.1767 13.4302 36.1333 15.0213C36.0967 16.5356 34.7526 17.8399 33.2317 17.8365Z'
        fill='#AFE7BB'
      />
    </svg>
  );
};
