import { SectionSubTitle } from '../landing/SectionSubTitle';
import { SectionMainText } from '../landing/SectionMainText';

const infoList = [
  {
    number: 10,
    title: 'Unique Applications',
    description:
      'Developed unique applications: transforming customer experiences, streamlining operations, and fueling measurable business growth. Experience the difference.',
  },
  {
    number: 25,
    title: 'Happy clients',
    description:
      'Delighted clients: experiencing business growth, improved efficiency, and customer satisfaction.',
  },
  {
    number: 200,
    title: 'Support hours',
    description:
      'Dedicated assistance, prompt issue resolution, unwavering commitment.',
  },
  {
    number: 17,
    title: 'Custom component',
    description:
      'Custom components developed in time and according to client`s requirements.',
  },
  {
    number: 8,
    title: 'Applications in development',
    description:
      'Several components are still in development and will be released soon.',
  },
  {
    number: 15,
    title: 'Requests under consideration',
    description:
      'We have multiple requests in the pipeline, but prior to initiating work, we diligently clarify requirements and discuss terms.',
  },
];

export const IndicatorsList = ({
  gridClassName,
}: {
  gridClassName?: string;
}) => {
  return (
    <div className='pt-12 lg:pt-16'>
      <ul
        className={`grid gap-12 grid-cols-2  ${
          gridClassName ? `lg:${gridClassName}` : 'lg:grid-cols-3'
        }  lg:gap-y-16 lg:gap-x-6`}
      >
        {infoList.map((info, index) => (
          <li key={index}>
            <SectionSubTitle className='text-center lg:text-left'>
              {info.number}
            </SectionSubTitle>
            <p
              className='text-lg font-medium leading-[1.625rem] tracking-[-0.0135rem] text-center mb-4 
                         lg:text-left lg:text-xl lg:leading-[1.75rem] lg:tracking-[-0.0175rem]'
            >
              {info.title}
            </p>
            <SectionMainText>{info.description}</SectionMainText>
          </li>
        ))}
      </ul>
    </div>
  );
};
