export const LinkedinIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 1 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_3921_23127)'>
        <path
          d='M18.5195 0.849609H1.47656C0.660156 0.849609 0 1.49414 0 2.29102V19.4043C0 20.2012 0.660156 20.8496 1.47656 20.8496H18.5195C19.3359 20.8496 20 20.2012 20 19.4082V2.29102C20 1.49414 19.3359 0.849609 18.5195 0.849609ZM5.93359 17.8926H2.96484V8.3457H5.93359V17.8926ZM4.44922 7.04492C3.49609 7.04492 2.72656 6.27539 2.72656 5.32617C2.72656 4.37695 3.49609 3.60742 4.44922 3.60742C5.39844 3.60742 6.16797 4.37695 6.16797 5.32617C6.16797 6.27148 5.39844 7.04492 4.44922 7.04492ZM17.043 17.8926H14.0781V13.252C14.0781 12.1465 14.0586 10.7207 12.5352 10.7207C10.9922 10.7207 10.7578 11.9277 10.7578 13.1738V17.8926H7.79688V8.3457H10.6406V9.65039H10.6797C11.0742 8.90039 12.043 8.10742 13.4844 8.10742C16.4883 8.10742 17.043 10.084 17.043 12.6543V17.8926Z'
          fill='#98A2B3'
        />
      </g>
      <defs>
        <clipPath id='clip0_3921_23127'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(0 0.849609)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
