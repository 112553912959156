import { AboutUsContacts } from '../components/about-us/AboutUsContacts';
import { AboutUsHero } from '../components/about-us/AboutUsHero';
import { AboutUsIndicators } from '../components/about-us/AboutUsIndicators';
import { AboutUsTechnologies } from '../components/about-us/AboutUsTechnologies';
import { AboutUsTeam } from '../components/about-us/AboutUsTeam';
import { OverWorldSection } from '../components/about-us/OverWorldSection';
import { BetterThenOthers } from '../components/generic/landing/BetterThanOthers';

export const AboutUsPage = () => {
  return (
    <>
      <AboutUsHero />
      <AboutUsIndicators />
      <AboutUsTechnologies />
      <AboutUsTeam />
      <BetterThenOthers />
      <OverWorldSection />
      <AboutUsContacts />
    </>
  );
};
