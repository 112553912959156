import { LinkedinIcon } from '../../icons/LinkedinIcon';
import clsx from 'clsx';

interface ITeamMemberItemProps {
  member: {
    image: string;
    name: string;
    position: string;
    aboutShort: string;
    about: string;
    linkedin: string;
  };
  bgColor?: string;
}

export const TeamMemberItem = ({ member, bgColor }: ITeamMemberItemProps) => {
  const { image, name, position, aboutShort, linkedin } = member;
  return (
    <li
      className={clsx(
        'flex flex-col items-center p-6 w-[276px]  rounded-[10px]',
        {
          'bg-cosmith-lavanda-150': bgColor === 'lavanda',
          'bg-cosmith-yellow-150': bgColor === 'yellow',
          'bg-cosmith-green-150': bgColor === 'green',
          'bg-cosmith-blue-150': bgColor === 'blue',
        }
      )}
    >
      <img
        src={image}
        alt={name}
        className='w-[96px] h-[96px] rounded-full mb-[20px] object-cover border-[0.75px] border-black border-solid border-opacity-10'
      />
      <p className='font-semibold text-[18px] leading-[28px] text-cosmith-primary-900'>
        {name}
      </p>
      <p className='mb-2 text-cosmith-primary-900'> {position}</p>
      <p className='mb-4 h-[72px] text-cosmith-primary-800 text-center line-clamp-3 text-balance'>
        {aboutShort}
      </p>
      <a
        href={linkedin}
        target='_blank'
        rel='noopener noreferrer'
        className='block hover:scale-150 transition-transform duration-300 ease-out p-1 focus-outline-custom'
      >
        <LinkedinIcon />
      </a>
    </li>
  );
};
