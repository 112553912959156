import { SectionSubTitle } from '../generic/landing/SectionSubTitle';
import { TeamList } from './TeamList';

export const AboutUsTeam = () => {
  return (
    <section className='py-12'>
      <SectionSubTitle className='text-center'>Our team</SectionSubTitle>
      <p className=' mb-12 text-cosmith-primary-800 text-[18px] text-center'>
        We're a small team striving for great achievements.
      </p>
      <TeamList />
    </section>
  );
};
