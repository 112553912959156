import { HelpForm } from '../talk-to-an-expert/HelpForm';
import { BusinessFeaturesWrapper } from '../../components/generic/landing/BusinessFeaturesWrapper';

const features = [
  { id: 1, value: 'Business analysis' },
  { id: 2, value: 'Design' },
  { id: 3, value: 'Development  ' },
  { id: 4, value: 'Testing' },
  { id: 5, value: 'Support' },
  { id: 6, value: 'Consulting' },
  { id: 7, value: 'Creating new components' },
  { id: 8, value: 'Extending existing components' },
];
export const AboutUsContacts = () => {
  return (
    <section
      id='aboutUsContacts'
      className='py-10 flex justify-between items-center  h-[1250px] lg:h-[794px]'
    >
      <div className='absolute w-full left-0 lg:h-[794px] h-[1250px] bg-cosmith-purple-300 z-[-1]'></div>

      <div className='px-4 lg:px-32'>
        <BusinessFeaturesWrapper
          title='Feel free to contact us!'
          secondChildren={<HelpForm />}
          features={features}
        >
          Our specialists can create a project from scratch, enhance an existing
          one, add new features, fix bugs, or assist you in understanding your
          current project.
        </BusinessFeaturesWrapper>
      </div>
    </section>
  );
};
