import { AdaptiveImageCard } from '../generic/AdaptiveImageCard';
import TimeSavings_tablet from '../../assets/main-page/TimeSavings-tablet.png';
import TimeSavings_pc from '../../assets/main-page/TimeSavings-pc.png';
import { ResponsivePicture } from '../generic/ResponsivePicture';
import { InfoDescription } from '../generic/landing/info-section/InfoDescription';
import { InfoFeatures } from '../generic/landing/info-section/InfoFeatures';
import { BuyNowButton } from '../generic/landing/BuyNowButton';
import LinkGreen from '../../icons/LinkGreen.svg';
import CalendarPurple from '../../icons/CalendarPurple.svg';

const images = {
  mobile: {
    src: TimeSavings_pc,
    width: 390,
  },
  tablet: {
    src: TimeSavings_tablet,
    width: 482,
  },
  pc: {
    src: TimeSavings_pc,
    width: 588,
  },
};

export const TimeSavingsSection = () => {
  return (
    <div className='pt-6 pb-12 lg:p-0 lg:ml-[-2rem] xl:ml-0'>
      <AdaptiveImageCard
        reversed
        secondChildren={
          <ResponsivePicture
            imageUrl={TimeSavings_pc}
            images={images}
            className="reletive flex items-center 
                          after:content-[' '] after:h-[358px] after:w-[358px]
                          after:absolute after:bg-[url('/src/assets/BackgroundRight.svg')] after:bg-no-repeat after:right-0 after:bg-left
                          sm:after:h-[588px] sm:after:w-[588px]
                          lg:after:bg-none 
                          xl:after:bg-[url('/src/assets/BackgroundLeft.svg')] xl:after:left-0
                          xl:after:ml-[-8rem] xl:after:bg-right"
          />
        }
      >
        <div>
          <div className='mb-12 lg:mb-8'>
            <InfoDescription title='Time Savings'>
              Developers can save time when searching for components that they
              need for their projects, allowing them to focus on other aspects
              of development such as coding or designing an interface without
              compromising quality or efficiency.
            </InfoDescription>
            <div
              className='lg:flex lg:flex-col lg:gap-6 lg:mt-8 lg:mb-8 lg:max-w-[331px]
            xl:flex-row xl:gap-6 xl:max-w-[588px] '
            >
              <InfoFeatures
                imageUrl={CalendarPurple}
                title='Easy to navigate website '
              >
                Our user-friendly interface simplifies your online journey,
                making it effortless to find component for you application
              </InfoFeatures>

              <InfoFeatures
                imageUrl={LinkGreen}
                title='Effortless installation'
              >
                Our components are designed for easy installation and
                configuration, allowing users to seamlessly integrate them into
                their applications.
              </InfoFeatures>
            </div>
          </div>

          <div className='flex justify-center lg:block'>
            <BuyNowButton />
          </div>
        </div>
      </AdaptiveImageCard>
    </div>
  );
};
