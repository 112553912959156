import { AboutUasCosmithCard } from './AboutUsCosmithCard';
import { TeamMemberItem } from './TeamMemberItem';
import { teamData } from './teamData';

export const TeamList = () => {
  const getBgColor = (index: number) => {
    let bgColor = 'blue';

    if (index === 0) {
      bgColor = 'lavanda';
    } else if (index === 1 || index === teamData.length - 1) {
      bgColor = 'yellow';
    } else if (index === 2 || index === teamData.length - 2) {
      bgColor = 'green';
    }
    return bgColor;
  };

  return (
    <ul className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 justify-items-center'>
      {teamData.map((member, index) => {
        const bgColor = getBgColor(index);
        return (
          <TeamMemberItem key={member.id} member={member} bgColor={bgColor} />
        );
      })}
      <AboutUasCosmithCard />
    </ul>
  );
};
